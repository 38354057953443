<template>
    <div class="container-fluid reset-container">
        <div class="row justify-content-center g-center">
            <div class="col-md-6 col-lg-4">
                <div class="reset-form position-relative">
                    <img src="/img/logo-clean.png" alt="Logo" class="logo">
                    <form @submit.prevent="reset">
                        <div class="mb-3">
                            <label for="emailOrUsername" class="form-label">Email or phone number</label>
                            <input type="text" class="form-control" id="emailOrUsername" v-model="username" required>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary">Reset Password</button>
                        </div>
                    </form>
                    <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                        {{ errorMessage }}
                    </div>
                    <div class="text-center mt-3">
                        <router-link to="/login" class="btn btn-secondary">Back to Login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "@/lib/requests";

export default {
    data() {
        return {
            username: '',
            errorMessage: ''
        };
    },
    methods: {
        async reset() {
            try {
                const response = await requests.post("transactional/reset-password", {
                    'username': this.username
                });
                if (response && response.status === 201) {
                    this.$router.push('/confirm-change-password?token='+this.username);
                } else if (response && response.data && response.data.detail) {
                    this.errorMessage = response.data.detail;
                } else {
                    this.errorMessage = 'An unknown error occurred.';
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.detail) {
                    this.errorMessage = error.response.data.detail;
                } else {
                    this.errorMessage = 'An error occurred while resetting the password.';
                }
            }
        }
    }
};
</script>

<style scoped>
.reset-container {
    margin: auto;
}

.reset-form {
    background-color: #d5dadb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 3vh;
}

.logo {
    display: block;
    width: 55%;
    margin: auto;
    margin-bottom: 20px;
}
</style>
