<template>
  <div class="tpi-selector white-box">
    <h4>TPI Data</h4>

    <div v-if="tpiData.length">
      <!-- Selector for dates -->
      <div class="mb-3">
        <label for="dateSelector" class="form-label">Select a Date:</label>
        <select id="dateSelector" class="form-select" v-model="selectedTpiId" @change="onTpiSelect">
          <option value="" disabled>Please select a TPI</option>
          <option v-for="(tpi, index) in tpiData" :key="tpi.tpi_id" :value="tpi.tpi_id">
            {{ (index + 1) }} - {{ tpi.start_date !== 'Not started' ? new Date(tpi.start_date).toLocaleString() : 'TPI not started' }} ({{ tpi.completion }}%)
          </option>
        </select>
      </div>
      
      <!-- Show TPIUserView component if a TPI is selected, with a key to force re-render on change -->
      <div v-if="selectedTpiId">
        <TPIUserView :key="selectedTpiId" :tpi-id="selectedTpiId" :user-id="userId" />
      </div>
    </div>
    <div v-else>
      <p>No TPI data available for this user.</p>
      <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import requests from '@/lib/requests';
import { getToken } from "@/lib/auth";
import TPIUserView from '@/components/user/TPIUserView.vue';

export default {
  name: 'TpiSelector',
  components: {
    TPIUserView
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const tpiData = ref([]);
    const selectedTpiId = ref(''); // Initialize as an empty string to show the default option
    const errorMessage = ref('');
    const userId = ref(props.id);

    // Fetch TPI data on component mount
    const fetchTpiData = async () => {
      try {
        const response = await requests.get(`/tpi/list/active-tpi/${props.id}`, {}, getToken());
        if (response && response.data) {
          tpiData.value = response.data;
          if (tpiData.value.length === 0) {
            errorMessage.value = 'No TPI data available.';
          }
        } else {
          errorMessage.value = 'No TPI data found.';
        }
      } catch (error) {
        errorMessage.value = 'An error occurred while fetching TPI data.';
        console.error(error);
      }
    };

    const onTpiSelect = () => {
      // Ensure the selected TPI ID triggers the conditional display of TPIUserView
      if (!selectedTpiId.value) {
        errorMessage.value = 'Please select a valid TPI.';
      }
    };

    onMounted(() => {
      fetchTpiData();
    });

    return {
      tpiData,
      selectedTpiId,
      errorMessage,
      onTpiSelect,
      userId
    };
  }
};
</script>

<style scoped>
.white-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.table th, .table td {
  vertical-align: middle;
}

.text-danger {
  color: #dc3545;
}
</style>
