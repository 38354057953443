<template>
    <div>
        <!-- Search bar -->
        <div class="white-box text-center">
            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="firstName" class="form-label">First Name:</label>
                    <input type="text" class="form-control" id="firstName" v-model="first_name" />
                </div>
                <div class="col-md-6">
                    <label for="lastName" class="form-label">Last Name:</label>
                    <input type="text" class="form-control" id="lastName" v-model="last_name" />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="email" class="form-label">Email:</label>
                    <input type="text" class="form-control" id="email" v-model="email" />
                </div>
                <div class="col-md-6">
                    <label for="phone" class="form-label">Phone:</label>
                    <div class="input-group">
                        <span class="input-group-text">+</span>
                        <input type="text" class="form-control col-2" v-model="country_code" placeholder="33"
                            style="max-width: 80px;" />
                        <input type="text" class="form-control" v-model="phone" placeholder="Phone number" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col text-center">
                    <button class="btn btn-primary" type="button" @click="onSearch">Search</button>
                </div>
            </div>
        </div>

        <!-- Total users info and pagination -->
        <div class="white-box text-center">
            <p>
                Total users: {{ total_users }}
            </p>

            <!-- Pagination -->
            <div v-if="totalPages > 1" class="pagination-container">
                <button class="btn page-btn" :class="{ active: currentPage === 0 }"
                    @click="onPageSelected(0)">1</button>
                <button v-for="page in displayedPages" :key="page" class="btn page-btn"
                    :class="{ active: page === currentPage }" @click="onPageSelected(page)">
                    {{ page + 1 }}
                </button>
                <button class="btn page-btn" :class="{ active: currentPage === totalPages - 1 }"
                    @click="onPageSelected(totalPages - 1)">
                    {{ totalPages }}
                </button>
            </div>
            <div v-else-if="totalPages === 1" class="pagination-container">
                <button class="btn page-btn active">1</button>
            </div>
        </div>

        <!-- User list -->
        <div class="user-list">
            <div v-for="(user, index) in users" :key="index" class="user-card list-group-item mb-2">
                <div class="row">
                    <div class="col-md-3">
                        <font-awesome-icon icon="user" class="icon me-2" />
                        <strong>{{ user.first_name || 'N/A' }} {{ user.last_name || 'N/A' }}</strong>
                    </div>
                    <div class="col-md-4">
                        <font-awesome-icon icon="envelope" class="icon me-2" />
                        {{ user.email || 'N/A' }}
                    </div>
                    <div class="col-md-3">
                        <font-awesome-icon icon="phone" class="icon me-2" />
                        +{{ user.country_code || '' }} {{ user.phone ? ' ' + user.phone : 'N/A' }}
                    </div>
                    <div class="col-md-2 text-end">
                        <button class="btn btn-outline-primary" @click="goToUserProfile(user.user_id)">
                            <font-awesome-icon icon="chart-bar" /> View profile
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import requests from '@/lib/requests';
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUser, faEnvelope, faPhone, faChartBar } from '@fortawesome/free-solid-svg-icons';

// Adding necessary icons to the library
library.add(faUser, faEnvelope, faPhone, faChartBar);

export default {
    name: 'UserSearch',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            first_name: "",
            last_name: "",
            email: "",
            country_code: "",
            phone: "",
            users: [],
            total_users: 0,
            currentPage: 0,
            apiUrl: "/user/list"  // Ensuring the correct API endpoint
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total_users / 25);
        },
        displayedPages() {
            const pages = [];
            const maxDisplayedPages = 5;
            let startPage = Math.max(1, this.currentPage - Math.floor(maxDisplayedPages / 2));
            let endPage = Math.min(this.totalPages - 2, this.currentPage + Math.floor(maxDisplayedPages / 2));

            if (endPage - startPage < maxDisplayedPages - 1) {
                startPage = Math.max(1, endPage - maxDisplayedPages + 1);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        }
    },
    created() {
        // Ensure the user list is fetched when the component is created
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            const payload = {
                offset: this.currentPage || 0,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                country_code: this.country_code,
                phone: this.phone
            };

            try {
                // Correct the URL and ensure it's targeting the right API endpoint
                const resp = await requests.post(this.apiUrl, payload, getToken());
                this.users = resp?.data?.user_list || [];
                this.total_users = resp?.data?.total_users || 0;
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        },
        onSearch() {
            this.currentPage = 0;
            this.fetchUsers();
        },
        onPageSelected(page) {
            this.currentPage = page;
            this.fetchUsers();
        },
        goToUserProfile(id) {
            this.$router.push('/users/profile/' + id);
        }
    }
};
</script>


<style scoped>
.iblock {
    display: inline-block;
    width: auto;
    margin-left: 10px;
}

.ibutton {
    display: inline-block;
    margin-left: 10px;
}

.white-box {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.pagination-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
}

.page-btn {
    border: 1px solid #ced4da;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.page-btn:hover {
    background-color: #f0f0f0;
}

.page-btn.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.user-list {
    margin-top: 20px;
}

.user-card {
    padding: 15px;
    border-radius: 5px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
    color: #6c757d;
}

.user-card .btn-outline-primary {
    font-size: 14px;
}

.user-card strong {
    font-size: 16px;
}

.row {
    margin-bottom: 15px;
}

.input-group-text {
    width: 35px;
    text-align: center;
}

input.form-control.col-2 {
    max-width: 80px;
    /* Adjusted size for the country code field */
}

.btn-primary {
    width: 100px;
    margin-top: 10px;
}
</style>
