<template>
  <div class="user-tpi-view">
    <h2>User TPI View</h2>
    
    <!-- Display basic information about the user and the TPI -->
    <div class="tpi-info mb-4">
      <h4>User ID: {{ userId }}</h4>
      <h4>TPI ID: {{ tpiId }}</h4>
    </div>

    <!-- Display TPIUserView component with the tpi_id -->
    <TPIUserView :tpi-id="tpiId" />

    <!-- Error message in case something goes wrong -->
    <div v-if="errorMessage" class="alert alert-danger mt-3">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import TPIUserView from '@/components/user/TPIUserView.vue';  // Make sure the path is correct

export default {
  name: 'TPIView',
  components: {
    TPIUserView
  },
  setup() {
    const route = useRoute();
    const userId = ref(String(route.params.id));  // Convert to string
    const tpiId = ref(String(route.params['tpi-id']));  // Convert to string
    const errorMessage = ref('');

    onMounted(() => {
      if (!tpiId.value) {
        errorMessage.value = 'TPI ID not found!';
      }
    });

    return {
      userId,
      tpiId,
      errorMessage
    };
  }
};
</script>

<style scoped>
.user-tpi-view {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tpi-info {
  margin-bottom: 20px;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 10px;
  border-radius: 4px;
}
</style>
