<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Permissions</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Navigation bar -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <!-- Navigation items -->
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminDashboard">
                                        <font-awesome-icon icon="arrow-left" class="me-2" /> Back
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <!-- Notice area -->
        <div class="row mt-1">
            <div class="col">
                <div class="row mt-4">
                    <div class="col">
                        <ul class="list-group">
                            <li v-for="(object, index) in objects" :key="index"
                                class="mb-1 list-group-item d-flex justify-content-between align-items-center">
                                {{ object.name }}
                                <span>
                                    <span class="update-click " :class="getPermissionClass(index, 0)" @click="updatePermission(index, object.object_id, 0)">Create</span><br />
                                    <span class="update-click" :class="getPermissionClass(index, 1)" @click="updatePermission(index, object.object_id, 1)">Read</span><br />
                                    <span class="update-click" :class="getPermissionClass(index, 2)" @click="updatePermission(index, object.object_id, 2)">Update</span><br />
                                    <span class="update-click" :class="getPermissionClass(index, 3)" @click="updatePermission(index, object.object_id, 3)">Delete</span>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getToken } from "@/lib/auth";
import requests from "@/lib/requests";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft, faXmark);

export default {
    name: 'ObjectsDashboard',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            objects: [],
            groupId : null
        };
    },
    created() {
        this.groupId = this.$route.params.id
        this.fetchData();
    },
    methods: {
        async updatePermission(index, id, type) {
            this.objects[index].permissions[type] = this.objects[index].permissions[type] == 1 ? 0 : 1;
            const permissions = this.objects[index].permissions.join('');
            console.log(permissions)
            console.log(id)
           await requests.post("/permissions/group-permissions", {
                "group_id": this.groupId,
                "object_id": id,
                "permissions": permissions
            }, getToken());
        },
        getPermissionClass(id, type) {
            if (this.objects[id].permissions[type] == 1) {
                return "isActive";
            }
            return "";
        },
        goToAdminDashboard() {
            this.$router.go(-1);
        },
        async fetchData() {
            const response = await requests.get("/permissions/group-permissions/" + this.groupId, 
            null, getToken());
            this.objects = response.data;
            console.log(this.objects)
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}

.alert-info p {
    margin-bottom: 0;
}

.update-click {
    padding: 3px;
}

.update-click:hover {
    cursor: pointer;
    background-color: black;
    color: white;
}

.isActive {
    background-color: aquamarine;
}
</style>