<!-- src/views/ObjectsDashboard.vue -->
<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Users</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="inviteUser">Invite user</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>

        <!-- UserSearch component -->
        <div class="row mt-4">
            <div class="col">
                <UserSearch 
                    :initial-filter="filter"
                    :initial-page="currentPage"
                    api-url="user/list"
                />
            </div>
        </div>
    </div>
</template>

<script>
import UserSearch from '@/components/UserSearch.vue';

export default {
    name: 'ObjectsDashboard',
    components: {
        UserSearch
    },
    data() {
        return {
            filter: this.$route.query.filter || "",
            currentPage: parseInt(this.$route.query.page, 10) || 0
        };
    },
    methods: {
        inviteUser() {
            this.$router.push('/users/invite');
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}
</style>
