<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Devices Dashboard</h2>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!-- Navigation bar -->
                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <!-- Navigation items -->
                    <div class="container-fluid">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminDashboard">
                                        <font-awesome-icon icon="arrow-left" class="me-2" /> Back
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="goToAdminBrandCreate">
                                        Create brand
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <!-- Notice area -->
        <div class="row mt-4">
            <div class="col">
                <div class="alert alert-info text-center" role="alert">
                    <p>
                        Here you can create brands and assign models to each brand.
                    </p>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <ul class="list-group">
                    <li v-for="(brand, index) in brands" :key="index"
                        class="mb-1 list-group-item d-flex justify-content-between align-items-center">
                        {{ brand.name }}
                        <span>
                            <button class="btn btn-primary" @click="goToAdminBrandPage(brand.brand_id)">View models</button>&nbsp;
                            <button class="btn btn-danger" @click="confirmDeleteBrand(brand.brand_id)">
                             <font-awesome-icon icon="xmark" />
                            </button>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft, faXmark);

export default {
    name: 'ObjectsDashboard',
    components: {
        FontAwesomeIcon
    },
    data() {
        return {
            brands: []
        };
    },
    created() {
        this.fetchGroups()
    },
    methods: {
        async fetchGroups() {
            try {
                const response = await requests.get("device/brands", {}, getToken());
                this.brands = response.data;
            } catch (error) {
                console.error('Error fetching objects:', error);
            }
        },
        goToAdminDashboard() {
            this.$router.go(-1);
        },
        goToAdminBrandCreate() {
            this.$router.push('/admin/brand/create');
        },
        goToAdminBrandPage(brandId) {
            this.$router.push('/admin/brand/' + brandId);
        },
        confirmDeleteBrand(brandId) {
            if (window.confirm('Are you sure you want to delete this group?')) {
                this.deleteBrand(brandId);
            }
        },
        async deleteBrand(brandId) {
            try {
                await requests.delete("device/brand/" + brandId, getToken());
                this.fetchGroups()
            } catch (error) {
                console.error('Error deleting object:', error);
            }

        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.nav-item:hover {
    cursor: pointer;
    background-color: rgba(138, 43, 226, .4);
}

.alert-info p {
    margin-bottom: 0;
}

</style>