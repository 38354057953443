<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">

        <!-- User Information Section -->
        <div v-if="userData" class="white-box p-4 mb-5">
          <h2 class="text-center mb-4">Profile Information</h2>
          <div v-if="profileError" class="alert alert-danger" role="alert">
            {{ profileError }}
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" class="form-control" id="email" v-model="userData.email">
          </div>
          <div class="mb-3 row">
            <div class="col-4">
              <label for="countryCode" class="form-label">Country Code</label>
              <div class="input-group">
                <span class="input-group-text">+</span>
                <input type="text" class="form-control" id="countryCode" v-model="userData.country_code">
              </div>
            </div>
            <div class="col-8">
              <label for="phone" class="form-label">Phone</label>
              <input type="text" class="form-control" id="phone" v-model="userData.phone">
            </div>
          </div>
          <div class="mb-3">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" class="form-control" id="lastName" v-model="userData.last_name">
          </div>
          <div class="mb-3">
            <label for="title" class="form-label">Title</label>
            <input type="text" class="form-control" id="title" v-model="userData.title">
          </div>
          <div class="mb-3">
            <label for="age" class="form-label">Age</label>
            <input type="number" class="form-control" id="age" v-model="userData.age">
          </div>
          <div class="text-center">
            <button class="btn btn-primary" @click="updateProfile">Save Changes</button>
          </div>
        </div>

        <!-- Password Change Section -->
        <div class="white-box p-4">
          <h3 class="text-center">Change Password</h3>

          <div v-if="passwordError" class="alert alert-danger" role="alert">
            {{ passwordError }}
          </div>

          <div class="mb-3">
            <label for="oldPassword" class="form-label">Old Password</label>
            <input type="password" class="form-control" id="oldPassword" v-model="oldPassword">
          </div>
          <div class="mb-3">
            <label for="newPassword" class="form-label">New Password</label>
            <input type="password" class="form-control" id="newPassword" v-model="newPassword">
          </div>
          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm New Password</label>
            <input type="password" class="form-control" id="confirmPassword" v-model="confirmPassword">
          </div>
          <div class="text-center">
            <button class="btn btn-primary" @click="changePassword">Change Password</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";

export default {
  data() {
    return {
      userData: null,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      profileError: null,
      passwordError: null
    };
  },
  async mounted() {
    await this.fetchUserData();
  },
  methods: {
    async fetchUserData() {
      try {
        const token = getToken();
        const response = await requests.get("user", {}, token);
        this.userData = response.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    async updateProfile() {
      try {
        this.profileError = null;

        const token = getToken();
        const payload = {
          email: this.userData.email,
          phone: this.userData.phone,
          country_code: this.userData.country_code,
          last_name: this.userData.last_name,
          title: this.userData.title,
          age: this.userData.age
        };
        await requests.put("user", payload, token);
        window.location.reload();
      } catch (error) {
        console.error('Error updating profile:', error);
        this.profileError = "Failed to update profile. Please try again.";
      }
    },
    async changePassword() {
      try {
        this.passwordError = null;

        const token = getToken();
        if (this.newPassword !== this.confirmPassword) {
          this.passwordError = "New password and confirmation do not match!";
          return;
        }

        const payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword
        };

        await requests.put("auth/user/password", payload, token);
        window.location.reload();
      } catch (error) {
        console.error('Error changing password:', error);
        this.passwordError = "Failed to change password. Please try again.";
      }
    }
  }
};
</script>

<style scoped>
.container {
  margin-top: 3rem;
}

.white-box {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

h2, h3 {
  margin-bottom: 1.5rem;
}

.text-center {
  margin-top: 2rem;
}

.alert {
  margin-bottom: 1rem;
}

.input-group-text {
  background-color: white;
}
</style>
