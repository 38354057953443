export const setToken = (token) => {
    localStorage.setItem('jwtToken', token);
  };
  
export const getToken = () => {
    return localStorage.getItem('jwtToken');
};
  
// Function to remove JWT token from localStorage
export const removeToken = () => {
    localStorage.removeItem('jwtToken');
};

export const isTokenExpired = (token) => {
    const decodedToken = parseJwt(token);
    if (!decodedToken) {
      return true; // Token is invalid or cannot be decoded
    }
  
    // Get the expiration date from the decoded token
    const expirationDate = new Date(decodedToken.exp * 1000); // Convert to milliseconds
  
    // Check if the expiration date is in the past
    return expirationDate.getTime() < Date.now();
};

const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
      return null; // Token is invalid or cannot be decoded
    }
};