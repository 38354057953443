<template>
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h2 class="mt-5 mb-4">Create Object</h2>
            </div>
        </div>
        <!-- Error message div -->
        <div class="row" v-if="errorMessage">
            <div class="col-md-6 offset-md-3">
                <div class="alert alert-danger" role="alert">
                    {{ errorMessage }}
                </div>
            </div>
        </div>
        <!-- Object creation form -->
        <div @click="goBack" class="b-btn">
            <font-awesome-icon icon="arrow-left" class="me-2" /> Back
        </div>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <form @submit.prevent="createObject">
                    <div class="mb-3">
                        <label for="objectName" class="form-label">Object Name</label>
                        <input type="text" class="form-control" id="objectName" v-model="newObjectName" required>
                    </div>
                    <button type="submit" class="btn btn-primary">Create</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import requests from "@/lib/requests";
import { getToken } from "@/lib/auth";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowLeft);

export default {
    name: 'CreateObject',
    data() {
        return {
            newObjectName: '',
            errorMessage: ''
        };
    },
    components: {
        FontAwesomeIcon
    },
    methods: {
        async createObject() {
            try {
                const token = getToken();
                await requests.post('objects', { name: this.newObjectName }, token);
                // Redirect to /admin/objects after creating the object
                this.$router.push('/admin/objects');
            } catch (error) {
                // Display error message
                this.errorMessage = "Error creating object. Make sure it doesn't already exist.";
                console.error('Error creating object:', error);
            }
        },
        goBack() {
            this.$router.push('/admin/objects');
        }
    }
};
</script>

<style scoped>
h2 {
    font-size: 24px;
}

.b-btn:hover {
    cursor: pointer;
}
</style>
