<template>
    <div class="container-fluid login-container">
        <div class="row justify-content-center g-center">
            <div class="col-md-6 col-lg-4">
                <div class="login-form position-relative">
                    <img src="/img/logo-clean.png" alt="Logo" class="logo">
                    <form @submit.prevent="login">
                        <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <input type="text" class="form-control" id="username" v-model="username" required>
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" v-model="password" required>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-primary">Login</button>
                        </div>
                    </form>
                    <div v-if="errorMessage" class="alert alert-danger mt-3" role="alert">
                        {{ errorMessage }}
                    </div>
                    <p class="forgot-password text-center m-2" @click="redirectToResetPassword">Forgot your password?
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setToken } from "@/lib/auth";
import requests from "@/lib/requests";

export default {
    data() {
        return {
            username: '',
            password: '',
            errorMessage: ''
        };
    },
    methods: {
        async login() {
            try {
                const formData = new FormData();
                formData.append('username', this.username);
                formData.append('password', this.password);
                const response = await requests.post('auth/user/login', formData)
                const { token } = response.data;
                setToken(token);
                this.$router.push('/');
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    this.errorMessage = 'Invalid username or password';
                } else {
                    this.errorMessage = 'An error occurred. Please try again later.';
                }
            }
        },
        redirectToResetPassword() {
            this.$router.push('/reset-password');
        }
    }
};
</script>

<style scoped>
.login-container {
    margin: auto;
}

.login-form {
    background-color: #d5dadb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 3vh;
}

.logo {
    display: block;
    width: 55%;
    margin: auto;
    margin-bottom: 20px;
}

.forgot-password {
    cursor: pointer;
    color: #007bff;
    /* Link color */
}

.forgot-password:hover {
    color: #0056b3;
    /* Hover color */
}
</style>